import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { RightRailMessage } from './rightRailMessage/rightRailMessage.component';
import { CARD_IMAGE } from '../utils/constants';
import {
  getPaymentEstimatorDetails,
  loadEstimatorScript
} from '../utils/payment-estimator-utils';
import { clickEvent } from '../utils/publisher';
import ProXtraDrawer from '../common/pro-xtra-drawer.component';
import { formatPrice } from '../utils/general';

export const PLCCB2BRightRailMessage = (props) => {
  const { plccEligibleAmount, saleAmount } = props;
  const cardImage = CARD_IMAGE.PLCR_HOMEDEPOT;
  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';
  const { applyNowUrl } = paymentEstimatorDetails || {};

  useEffect(() => {
    const loadPaymentEstimator = () => {
      setPaymentEstimatorDetails(getPaymentEstimatorDetails(saleAmount, isMobile, false, false, 'opc'));
    };

    loadEstimatorScript(
      paymentEstimatorVersion,
      loadPaymentEstimator,
      isMobile
    );
  }, [saleAmount]);

  const analyticEvent = () => {
    clickEvent('plcc right rail message', 'plcc-opc');
  };

  const getTitle = () => {
    return (
      <p className="sui-font-w-bold">Earn Perks <span className="sui-text-brand">4X</span> Faster*</p>
    );
  };

  const getDescription = () => {
    const boldText = `$${(Math.floor((Number(plccEligibleAmount))) * 4).toLocaleString()} in Perks Spend`;
    return (
      <span>Earn <span className="sui-font-w-bold">{boldText}</span> on a{' '}
        ${Math.floor(plccEligibleAmount).toLocaleString()} qualifying purchase when you join Pro Xtra, register
        and use your card.
      </span>
    );
  };

  const getApplyNowLink = () => {
    return applyNowUrl + `&saleAmt=${saleAmount}` || '';
  };

  return (
    <>
      <ProXtraDrawer applyNowUrl={getApplyNowLink()} closeDrawer={() => setShowDrawer(false)} open={showDrawer} />
      <RightRailMessage
        cardImage={cardImage}
        title={getTitle()}
        description={getDescription()}
        applyNow={getApplyNowLink()}
        viewDetails={() => setShowDrawer(true)}
        applyClick={analyticEvent}
      />
    </>
  );
};

PLCCB2BRightRailMessage.propTypes = {
  plccEligibleAmount: PropTypes.number,
  saleAmount: PropTypes.number
};

PLCCB2BRightRailMessage.defaultProps = {
  plccEligibleAmount: 0,
  saleAmount: 0
};