import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter
} from '@one-thd/sui-atomic-components';
import { CARD_IMAGE } from '../utils/constants';
import { clickEvent } from '../utils/publisher';
import { getDynamicOffer } from '../utils/general';

const PrequalInfoDrawer = (
  {
    closeDrawer,
    open,
    isB2B,
    paymentEstimatorLoaded,
    firstName,
    applynowURL,
    price
  }) => {

  const [interestRate, setInterestRate] = useState('');
  const [promoEndDate, setPromoEndDate] = useState('');

  const getInterestRate = () => {
    if (window?.THD?.PaymentEstimator?.interestRate && typeof window?.THD?.PaymentEstimator?.interestRate === 'function'
    ) {
      return window?.THD?.PaymentEstimator?.interestRate();
    }
    return '';
  };

  const getDefaultPromoEndDate = () => {
    if (window?.THD?.PaymentEstimator?.defaultPromoEndDate
      && typeof window?.THD?.PaymentEstimator?.defaultPromoEndDate === 'function'
    ) {
      setPromoEndDate(window?.THD?.PaymentEstimator?.defaultPromoEndDate());
    }
  };

  useEffect(() => {
    if (paymentEstimatorLoaded) {
      getDefaultPromoEndDate();
      setInterestRate(getInterestRate());
    }
  }, [paymentEstimatorLoaded, window?.THD?.PaymentEstimator]);

  const applyNowClick = () => {
    window.location.href = applynowURL;
    clickEvent('plcc-apply now');
  };

  return (
    <>
      {paymentEstimatorLoaded ? (
        <Drawer
          open={open}
          onClose={closeDrawer}
          position="right"
          id="drawer-terms-conditions"
        >
          <DrawerHeader
            title={(
              <span
                className="sui-h4-bold sui-line-clamp-unset sui-text-primary
              sui-font-w-bold"
              >
                {firstName}, You are Pre-qualified for a Home Depot Consumer Card!
              </span>
            )}
            onClose={closeDrawer}
          />
          <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
            <DrawerBody>
              <div className="sui-flex sui-flex-col sui-gap-4">
                <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                  <img
                    src={isB2B ? CARD_IMAGE.PLCR_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT}
                    alt="Credit Card Icon"
                    width="185"
                    height="103"
                  />
                  <span className="sui-block sui-w-full sui-font-w-bold sui-text-center
                  sui-text-lg sui-mt-4"
                  > ${getDynamicOffer(price)} off
                  </span>
                </div>
                <div className="sui-text-lg">
                  Get <span className="sui-font-w-bold">$25 off</span> purchases of $25-$299*,
                  <span className="sui-font-w-bold">$50 off</span> purchase of $300-$999* or{' '}
                  <span className="sui-font-w-bold">$100 off</span> purchase of $1000+*{' '}
                  on your next qualifying purchase when you open a new card.{' '}
                  <span className="sui-font-w-bold">Plus, receive 6-months everyday financing**
                  </span>{' '}
                  on purchases $299 or more when you use your Home Depot Consumer Credit Card.
                </div>
                <div className="sui-text-lg">

                  <span data-testid="drawer-text" className="sui-font-w-bold">
                    NO INTEREST IF PAID IN FULL WITHIN 6 MONTHS**
                  </span> on purchases of $299 or more made on The Home Depot Consumer Credit Card.
                  Interest will be charged to your account from the purchase date if the purchase balance
                  (including premiums for optional credit insurance) is not paid in full within 6 months.
                </div>
                <div className="sui-border-1 sui-border-solid sui-p-2 sui-border-primary">
                  **With credit approval for qualifying purchases made on The Home Depot Consumer Credit Card.{' '}
                  APR: {interestRate}. Minimum interest charge: $2. See card agreement for details including
                  APR applicable to you.{' '}
                  Offer is valid for consumer accounts in good standing; see store for details.
                </div>
                <div className="sui-text-lg">
                  <span className="sui-font-w-bold">
                    *Offer is for new accounts, is subject to credit approval and valid now through{' '}
                    {promoEndDate}.&nbsp;
                  </span>
                  This offer is redeemable for either a $25 discount on a single-receipt purchase of $25
                  up to $299, a $50 discount on a single-receipt purchase of $300 up to $999 or a $100
                  discount on a single-receipt purchase of $1,000 or more, excluding tax and delivery fees,
                  within 30 days of your account open date at The Home Depot® retail stores or homedepot.com
                  when made with your The Home Depot Consumer Credit Card, The Home Depot Home Improver Card,
                  the Pro Xtra Credit Card, or The Home Depot Commercial Account. Coupon is on a single
                  transaction and expires upon use. Valid in the U.S., U.S.V.I., Puerto Rico and Guam.
                  Offer does not apply to prior purchases, The Home Depot Gift Cards or Certificates,
                  third party gift cards, installation products or services purchased in home,
                  non-discountable SKUs, Tool Rental or to Traeger®, Weber®, Bona®, Simplehuman®, Dacor®,
                  Viking®, Fisher & Paykel®, Sharp Insight™, Delonghi®, Buckhaven, Lynx®, Alfresco™, OCI,
                  Marvel®, Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef Collection, Bosch Benchmark® Series,
                  Liebherr, Zephyr, Miele, Signature Kitchen Suite, Jenn-Air®, Thermador®, Jeld-Wen® Door Systems,
                  Monogram products and trade styles. Additional exclusions may apply which will be reflected
                  at checkout. May not be combined with other discounts; ask an Associate for details.
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="primary"
                data-testid="drawer-apply-now"
                onClick={applyNowClick}
                fullWidth
              >
                Apply Now
              </Button>
            </DrawerFooter>
          </div>
        </Drawer>
      ) : null}
    </>

  );
};

PrequalInfoDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  paymentEstimatorLoaded: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  open: PropTypes.bool,
  isB2B: PropTypes.bool,
  applynowURL: PropTypes.string,
};

PrequalInfoDrawer.defaultProps = {
  open: true,
  isB2B: false,
  applynowURL: ''
};

export default PrequalInfoDrawer;
