/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { Image } from '@thd-olt-component-react/core-ui';
import { Card, Typography, Link } from '@one-thd/sui-atomic-components';
import { clickEvent, loadEvent } from '../utils/publisher';
import { CARD_IMAGE, INFO_ICON } from '../utils/constants';

import { getPaymentEstimatorDetails, loadEstimatorScript, getIntroOfferLink } from '../utils/payment-estimator-utils';
import TermsAndConditionsDrawer from '../common/terms-and-conditions-drawer.component';
import ProXtraDrawer from '../common/pro-xtra-drawer.component';
import { Info } from '~/@one-thd/sui-icons';
import {
  displayPriceWithFixed2Decimal,
  getHDCards,
  hasConsumerCard,
  hasProxCard
} from '../utils/general';
import TermsAndConditionsDrawerPlccPrompt from '../common/terms-and-condition-drawer-plcc-prompt.component';
import { PromptMessage } from './promptMessage/promptMessage.component';

export function PLCCCheckoutPrompt(props) {
  const {
    plccEligibleAmount,
    saleAmount,
    isB2B,
    savedCards,
    setPaymentCard,
    paymentCard
  } = props;

  const cardImage = isB2B ? CARD_IMAGE.PLNP_HOMEDEPOT : CARD_IMAGE.PLCN_HOMEDEPOT;
  const experienceContext = useContext(ExperienceContext);
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.5.0';
  const [paymentEstimatorDetails, setPaymentEstimatorDetails] = useState(null);
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(false);
  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';
  const {
    promotionTerm, displayPrice, tooltipText
  } = paymentEstimatorDetails || {};
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const isAppliancePromo = tooltipText?.includes('Appliance');
  const isTieredPromo = tooltipText?.includes('24 Months');

  useEffect(() => {
    const loadPaymentEstimator = () => {
      setPaymentEstimatorDetails(
        getPaymentEstimatorDetails(saleAmount, isMobile, isAppliancePromo, isTieredPromo, 'opc')
      );
      setPaymentEstimatorLoaded(true);
    };

    if (!isB2B) {
      loadEstimatorScript(
        paymentEstimatorVersion,
        loadPaymentEstimator,
        isMobile
      );
    }
  }, [saleAmount]);

  const b2bTitle = () => {
    return (
      <span className="sui-font-w-bold">
        Earn Perks <span className="sui-text-brand">4X</span> Faster* When You Use Your Card
      </span>
    );
  };

  const b2cTitle = () => {
    return <span className="sui-font-w-bold">Switch to Your Saved Home Depot Credit Card</span>;
  };

  const b2cDescription = () => {
    return displayPrice ? (
      <>
        <span className="sui-font-w-bold sui-mr-1">
          ${displayPriceWithFixed2Decimal(Number(displayPrice))} per month** suggested payments with{' '}
          {promotionTerm} months**{' '}
          <span className="sui-font-normal">
            financing on this ${displayPriceWithFixed2Decimal(Number(saleAmount))} purchase when you use your
            Home Depot Consumer Credit Card.
          </span>
        </span>
      </>
    ) : null;
  };

  const b2bDescription = () => {
    return (
      <span>
        Earn ${displayPriceWithFixed2Decimal(Number(plccEligibleAmount) * 4)} in Perks Spend on a $
        {displayPriceWithFixed2Decimal(plccEligibleAmount)} qualifying purchase when you use your card.
      </span>
    );
  };

  const plccPromptThresholdValue = parseFloat(useConfigService('plccPromptThresholdValue')) || 300.00;
  const showB2CPLCCPrompt = !isB2B && (saleAmount >= plccPromptThresholdValue) && hasConsumerCard(savedCards);
  const showB2BPLCCPrompt = isB2B && hasProxCard(savedCards);
  const hdCards = getHDCards(savedCards);
  const hdCardSelected = paymentCard && ((paymentCard.cardType === 'HR' && isB2B)
    || (paymentCard.cardType === 'HC' && !isB2B));

  const handlePaymentCard = () => {
    setPaymentCard(hdCards[0]);
  };

  return ((showB2CPLCCPrompt || showB2BPLCCPrompt) && !hdCardSelected) ? (
    <React.Fragment data-testid="payment-option">
      <div data-testid="payment-option" className="sui-mt-4">
        {isB2B ? (
          <ProXtraDrawer closeDrawer={() => setDisplayDrawer(false)} open={displayDrawer} hideApplynow applyNowUrl={' '} />
        ) : (
          <TermsAndConditionsDrawerPlccPrompt
            closeDrawer={() => setDisplayDrawer(false)}
            open={displayDrawer}
            isAppliancePromo={isAppliancePromo}
            isTieredPromo={isTieredPromo}
            isB2B={isB2B}
            paymentEstimatorLoaded={paymentEstimatorLoaded}
            saleAmount={saleAmount}
            isAppliance={isAppliancePromo}
            isTiered={isTieredPromo}
          />
        )}
        {isB2B || (!isB2B && saleAmount >= plccPromptThresholdValue) ? (
          <PromptMessage
            cardImage={cardImage}
            title={isB2B ? b2bTitle() : b2cTitle()}
            description={isB2B ? b2bDescription() : b2cDescription()}
            payWithHomeDepot={handlePaymentCard}
            viewDetails={() => setDisplayDrawer(true)}
            isB2B={isB2B}
          />
        ) : null}
      </div>
    </React.Fragment>
  ) : null;
}

PLCCCheckoutPrompt.propTypes = {
  plccEligibleAmount: PropTypes.number,
  saleAmount: PropTypes.number,
  isB2B: PropTypes.bool,
  setPaymentCard: PropTypes.func.isRequired,
  savedCards: PropTypes.arrayOf(shape({
  })),
  paymentCard: shape({
  }),
};

PLCCCheckoutPrompt.defaultProps = {
  plccEligibleAmount: 0,
  saleAmount: 0,
  isB2B: false,
  savedCards: [],
  paymentCard: null,
};
